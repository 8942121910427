import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/react-quill/dist/quill.snow.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/react-circular-progressbar/dist/styles.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/quill/dist/quill.snow.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/sweetalert2/src/sweetalert2.scss");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/react-phone-number-input/style.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/react-day-picker/src/style.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/staging-business-app.tech1m.com/src/layouts/BaseLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/staging-business-app.tech1m.com/src/providers/NotificationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/forge/staging-business-app.tech1m.com/src/providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/forge/staging-business-app.tech1m.com/src/providers/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/forge/staging-business-app.tech1m.com/src/styles/tailwind.css");
