import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { User } from '@/store/types';

// Type for our state
export interface AuthState {
  authenticated: boolean;
  user?: User | null;
}

// Initial state
const initialState: AuthState = {
  authenticated: false,
  user: null,
};

// Actual Slice
export const authSlice = createSlice({
  name: 'auth',

  initialState,

  reducers: {
    reset: () => initialState,

    // Action to set the authentication status
    setAuthState(state, action: PayloadAction<AuthState>) {
      state.authenticated = action.payload.authenticated;
      state.user = action.payload.user;
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state: AuthState, action: AnyAction) => {
      return {
        ...state,
        ...action.payload.auth,
      } as AuthState;
    });
  },
});

export const { setAuthState } = authSlice.actions;

export default authSlice.reducer;
