import {
  applicationsSlice,
  setApplicationsState,
  updateApplication,
  removeApplication,
  restoreApplication,
  setPreviewingApplicationId,
} from './applications';

import authSliceReducer, { authSlice, AuthState, setAuthState } from './authSlice';

import jobsSliceReducer, {
  setJobsState,
  updateJob,
  removeJob,
  restoreJob,
  jobsSlice,
  JobsState,
} from './jobsSlice';

export {
  authSliceReducer,
  authSlice,
  setAuthState,
  jobsSliceReducer,
  setJobsState,
  updateJob,
  removeJob,
  restoreJob,
  jobsSlice,
  applicationsSlice,
  setApplicationsState,
  updateApplication,
  removeApplication,
  restoreApplication,
  setPreviewingApplicationId,
};

export type { AuthState, JobsState };
