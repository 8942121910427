export enum WelcomeStatus {
  'Done' = 'done',
  'NotDone' = 'notDone',
}

export enum ScoreColor {
  '90+' = '#1b6',
  '80+' = '#bd4',
  '61+' = '#fd2',
  '41+' = '#f74',
  '0+' = '#f34',
}
