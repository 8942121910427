'use client';

import { AppStore, makeStore } from '@/store';
import { useRef } from 'react';
import { Provider } from 'react-redux';
export default function StoreProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore();
  }
  return <Provider store={storeRef.current} data-sentry-element="Provider" data-sentry-component="StoreProvider" data-sentry-source-file="StoreProvider.tsx">{children}</Provider>;
}