import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { OfferDetailsData } from '@/modules/Offers/types';
import { ApplicationsResponseJson } from '@/modules/Jobs/types/applications';
// import { ApplicationsResponseData } from '@/modules/MainModule/applications/types/applicationsApiResponse';

// Type for our state
export interface ApplicationsState {
  availableApplications: ApplicationsResponseJson['data'];
  previewingApplicationId: string;
  previewJobId: string;
  availableOffers: OfferDetailsData[];
}

// Initial state
const initialState: ApplicationsState = {
  availableApplications: [],
  availableOffers: [],
  previewingApplicationId: '',
  previewJobId: '',
};

// Actual Slice
export const applicationsSlice = createSlice({
  name: 'applicationsState',
  initialState,
  reducers: {
    reset: () => initialState,
    // Action to set the authentication status
    setApplicationsState(
      state,
      action: PayloadAction<Pick<ApplicationsState, 'availableApplications'>>
    ) {
      state.availableApplications = action.payload.availableApplications;
    },

    updateApplication(state, action: PayloadAction<ApplicationsState['availableApplications'][0]>) {
      const updatedApplication = action.payload;
      const updatedApplications = state.availableApplications.map((j) => {
        if (j.id === updatedApplication.id) {
          return updatedApplication;
        }
        return j;
      });
      state.availableApplications = updatedApplications;
    },

    removeApplication(
      state,
      action: PayloadAction<ApplicationsState['availableApplications'][0]['id']>
    ) {
      const updatedApplications = state.availableApplications.filter(
        (j) => j.id !== action.payload
      );
      state.availableApplications = updatedApplications;
    },

    restoreApplication(
      state,
      action: PayloadAction<ApplicationsState['availableApplications'][0]>
    ) {
      const restoredApplication = action.payload;

      const updatedApplications = [
        ...state.availableApplications.filter((j) => j.id !== restoredApplication.id),
      ];
      state.availableApplications = updatedApplications;
    },

    setPreviewingApplicationId(
      state,
      action: PayloadAction<ApplicationsState['previewingApplicationId']>
    ) {
      state.previewingApplicationId = action.payload;
    },

    setPreviewJobId(state, action: PayloadAction<ApplicationsState['previewJobId']>) {
      state.previewJobId = action.payload;
    },

    setAvailableOffers(state, action: PayloadAction<Pick<ApplicationsState, 'availableOffers'>>) {
      state.availableOffers = action.payload.availableOffers;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state: ApplicationsState, action: AnyAction) => {
      return {
        ...state,
        ...action.payload.applicationsState,
      } as ApplicationsState;
    });
  },
});

export const {
  setApplicationsState,
  setAvailableOffers,
  updateApplication,
  removeApplication,
  restoreApplication,
  setPreviewingApplicationId,
  setPreviewJobId,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
