'use client';

import React, { createContext, useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
// import { ThemeProvider, useTheme } from 'next-themes';
import { HydrationBoundary, dehydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextUIProvider } from '@nextui-org/react';
import { env } from '@/env.mjs';
const ReactQueryDevtoolsProduction = React.lazy(() => import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
  default: d.ReactQueryDevtools
})));
function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// function ThemeWatcher() {
//   const { resolvedTheme, setTheme } = useTheme();

//   useEffect(() => {
//     const media = window.matchMedia('(prefers-color-scheme: light)');
//     function onMediaChange() {
//       const systemTheme = media.matches ? 'light' : 'dark';
//       if (resolvedTheme === systemTheme) {
//         setTheme('system');
//       }
//     }

//     onMediaChange();
//     media.addEventListener('change', onMediaChange);

//     return () => {
//       media.removeEventListener('change', onMediaChange);
//     };
//   }, [resolvedTheme, setTheme]);

//   return null;
// }

export const AppContext = createContext<{
  previousPathname?: string;
}>({});
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  const [showDevtools, setShowDevtools] = React.useState(Boolean(env.NEXT_PUBLIC_DEVTOOLS_ENABLED || false));
  React.useEffect(() => {
    // @ts-expect-error window is not defined in the browser
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);
  const pathname = usePathname();
  const previousPathname = usePrevious(pathname);
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000
      }
    }
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <HydrationBoundary state={dehydrate(queryClient)} data-sentry-element="HydrationBoundary" data-sentry-source-file="providers.tsx">
        <ReactQueryDevtools data-sentry-element="ReactQueryDevtools" data-sentry-source-file="providers.tsx" />
        {showDevtools && <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>}
        <AppContext.Provider value={{
        previousPathname
      }} data-sentry-element="unknown" data-sentry-source-file="providers.tsx">
          {/* <ThemeProvider attribute="class" disableTransitionOnChange>
            <ThemeWatcher />
           </ThemeProvider> */}
          <NextUIProvider data-sentry-element="NextUIProvider" data-sentry-source-file="providers.tsx">{children}</NextUIProvider>
        </AppContext.Provider>
      </HydrationBoundary>
    </QueryClientProvider>;
}