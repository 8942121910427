export const dualLayoutRoutes = [`/pricing/checkout`];
export const guestLayoutRoutes = [`/auth`, `/invitation`, `/verify/subscription`];
export const appSidebarLayoutRoutes = [
  `/career-hub`,
  `/dashboard`,
  `/help-center`,
  `/ai-sourcing`,
  `/assessments`,
  `/assessments/.+`,
  `/inbox`,
  `/insights`,
  `/integrations`,
  `/integrations/request`,
  `/invoices`,
  `/invoices/.+`,
  `/jobs`,
  `/jobs/.+/applications`,
  `/settings`,
  `/talent-pool`,
  `/talent-pool/setup`,
  `/talent-pool/.+`,
  `/talent-crm`,
];

export const appNoSidebarLayoutRoutes = [
  `/assessments/.+/candidate-result/.+`,
  `/assessments/builder`,
];

export const skipSubCheckRoutes = [`/pricing`];

export const specialClassNameRoutes = [{ route: `/pricing/checkout`, className: `bg-[#033fa4]` }];
