'use client';

import type { AppLayoutProps } from './types';
import AppLayout from '@/layouts/AppLayout';
import GuestLayout from '@/layouts/GuestLayout';
import { usePathname } from 'next/navigation';
import { appNoSidebarLayoutRoutes, appSidebarLayoutRoutes, dualLayoutRoutes, guestLayoutRoutes, skipSubCheckRoutes, specialClassNameRoutes } from '@/layouts/layoutRoutes';
export default function BaseLayout({
  children,
  ...props
}: AppLayoutProps) {
  const pathname = usePathname();
  const isGuestModule = guestLayoutRoutes.some(r => pathname.startsWith(r));
  const isAppLayoutWithSidebar = appSidebarLayoutRoutes.some(r => new RegExp(String.raw`^${r}$`).test(pathname));
  const isNotAppLayoutWithSidebar = appNoSidebarLayoutRoutes.some(r => new RegExp(String.raw`^${r}$`).test(pathname));
  const skipSubscriptionCheck = skipSubCheckRoutes.some(r => pathname.startsWith(r));
  const specialClassNameRoute = specialClassNameRoutes.find(r => pathname.startsWith(r.route));
  const isDualLayout = dualLayoutRoutes.find(r => pathname.startsWith(r));
  if (isDualLayout) {
    return <div>{children}</div>;
  }
  if (isGuestModule) {
    return <GuestLayout {...props}>{children}</GuestLayout>;
  }
  return <AppLayout {...props} className={specialClassNameRoute ? specialClassNameRoute.className : ''} sidebar={isAppLayoutWithSidebar && !isNotAppLayoutWithSidebar} skipSubscriptionCheck={skipSubscriptionCheck} data-sentry-element="AppLayout" data-sentry-component="BaseLayout" data-sentry-source-file="BaseLayout.tsx">
      {children}
    </AppLayout>;
}