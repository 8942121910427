import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { JobsResponseData } from '@/modules/Jobs/types/jobsApiResponse';

// Type for our state
export interface JobsState {
  availableJobs: JobsResponseData['data'];
}

// Initial state
const initialState: JobsState = {
  availableJobs: [],
};

// Actual Slice
export const jobsSlice = createSlice({
  name: 'jobsState',
  initialState,
  reducers: {
    reset: () => initialState,

    // Action to set the authentication status
    setJobsState(state, action: PayloadAction<JobsState>) {
      state.availableJobs = action.payload.availableJobs;
    },

    updateJob(state, action: PayloadAction<JobsState['availableJobs'][0]>) {
      const updatedJob = action.payload;
      const updatedJobs = state.availableJobs.map((j) => {
        if (j.id === updatedJob.id) {
          return updatedJob;
        }
        return j;
      });
      state.availableJobs = updatedJobs;
    },

    removeJob(state, action: PayloadAction<JobsState['availableJobs'][0]['id']>) {
      const updatedJobs = state.availableJobs.filter((j) => j.id !== action.payload);
      state.availableJobs = updatedJobs;
    },

    restoreJob(state, action: PayloadAction<JobsState['availableJobs'][0]>) {
      const restoredJob = action.payload;

      const updatedJobs = [...state.availableJobs.filter((j) => j.id !== restoredJob.id)];
      state.availableJobs = updatedJobs;
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state: JobsState, action: AnyAction) => {
      return {
        ...state,
        ...action.payload.jobsState,
      } as JobsState;
    });
  },
});

export const { setJobsState, updateJob, removeJob, restoreJob } = jobsSlice.actions;

export default jobsSlice.reducer;
