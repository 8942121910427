import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { env } from '@/env.mjs';
import { thunk } from 'redux-thunk';
import { authSlice, jobsSlice, applicationsSlice } from '@/store/slices';
import { announcementSlice } from './slices/announcement';
const slices = { authSlice, jobsSlice, applicationsSlice, announcementSlice };

const reducers = {
  [authSlice.name]: authSlice.reducer,
  [jobsSlice.name]: jobsSlice.reducer,
  [applicationsSlice.name]: applicationsSlice.reducer,
  [announcementSlice.name]: announcementSlice.reducer,
};

export const makeStore = () =>
  configureStore({
    devTools: env.NEXT_PUBLIC_DEVTOOLS_ENABLED || false,
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  });

export type AppStore = ReturnType<typeof makeStore>;

export type AppDispatch = AppStore['dispatch'];

// export type RootState = ReturnType<typeof store.getState>;
export type AppState = ReturnType<AppStore['getState']>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState, // RootState
  unknown,
  Action<string>
>;

export const wrapper = createWrapper<AppStore>(makeStore);
export const storeReducers = reducers;
export const storeSlices = slices;
export default makeStore();
